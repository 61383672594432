import "./App.css";

export const App = () => {
  return (
    <div>
      <h2>Home</h2>
      <p>The homepage</p>
    </div>
  );
};
